@import url('https://fonts.cdnfonts.com/css/akira-expanded');
 @import url('https://fonts.googleapis.com/css2?family=Archivo:wght@600&family=Roboto:wght@400;500&display=swap');
 html{
    height: -webkit-fill-available;
 }
 body{
     // font-family: 'Archivo', sans-serif;
     // font-family: 'Akira Expanded', sans-serif;
     height: -webkit-fill-available;
     margin: 0;
     background-color: #04080d;
     color: white;
     overflow-x: hidden !important;
}
 a {
     text-decoration: none;
}
 ::-webkit-scrollbar {
     width: 7px;
}
 ::-webkit-scrollbar-track {
     background: rgba(255, 255, 255, 0.10);
}
 ::-webkit-scrollbar-thumb {
     background: var(--0-caff-5, #0CAFF5);
}
 .main{
     width: 100%;
     height: 100vh;
}
 .header{
     width: 85%;
     height: 100px;
     display: flex;
     margin-top: 32px !important;
     align-items: center;
     margin: auto;
}
 .header_name{
     font-family: 'Akira Expanded', sans-serif;
     font-size: 35px;
     color: #2293fd;
}
 .firstpage{
     margin: auto;
     margin-top: 32px !important;
     width: 85%;
     margin-bottom: 80px;
     display: flex;
     justify-content: center;
     align-items: center;
}
 .firstpage_cont{
     display: flex;
     justify-content: center;
     align-items: center;
}
 .firstpage_left{
     width: 600px;
}
 .firstpage_right{
     width: 760px;
     height: 760px;
     background-image: url(./img/abstract.png);
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     margin-left: 208px;
     animation: rotation 10s infinite linear;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
 .firstpage_left_intr{
     color: white;
     font-size: 25px;
     font-family: 'Archivo', sans-serif;
}
 .firstpage_left_name{
     color: white;
     font-family: 'Akira Expanded', sans-serif;
     font-size: 62px;
     margin-top: 16px;
}
 .firstpage_left_profession{
     font-size: 27px;
     color: #2293fd;
     font-family: 'Archivo', sans-serif;
     margin-top: 24px;
}
 .firstpage_left_desc{
     color: rgba(255, 255, 255, 0.575);
     font-family: 'Archivo', sans-serif;
     font-size: 16px;
     letter-spacing: 1.6px;
     margin-top: 32px;
}
 .firstpage_left_button{
     width: 208px;
     height: 57.6px;
     background-color: #2293fd;
     font-size: 18px;
     color: black;
     font-family: 'Archivo', sans-serif;
     border-radius: 35px;
     display: flex;
     justify-content: center;
     align-items: center;
     letter-spacing: 3.2px;
     cursor: pointer;
     margin-top: 64px;
     transition: 0.2s;
}
 .firstpage_left_button:hover{
     box-shadow: 0px 7px 21px 0px rgba(12, 175, 245, 0.23);
     transition: 0.2s;
}
 .secondpage{
     margin: auto;
     width: 85%;
     margin-bottom: 160px;
     display: flex;
     align-items: center;
     justify-content: center;
}
 .secondpage_container{
     display: flex;
     align-items: center;
     justify-content: center;
}
 .secondpage_leftside_header{
     font-size: 25px;
     color: white;
     font-family: 'Archivo', sans-serif;
     margin-bottom: 50px;
}
 .secondpage_leftside_box{
     width: 365px;
     height: 611px;
     border: 13px solid #2293fd;
     display: flex;
     justify-content: center;
     align-items: center;
}
 .secondpage_leftside_box_avatar{
     width: 448px;
     height: 448px;
     border-radius: 50%;
     position: absolute;
     margin-left: 380px;
     background-image: url(./img/me.JPG);
     background-size: 180%;
     background-position: center;
}
.secondpage_rightside_allskills{
    width: 700px;
}
 .secondpage_rightside{
     max-width: 43%;
     margin-left: 480px;
     margin-top: 60px;
}
 .secondpage_rightside_name{
     color: white;
     font-size: 30px;
     font-family: 'Archivo', sans-serif;
}
 .secondpage_rightside_desc{
     font-size: 18px;
     color: #f4f4f4d2;
     font-family: 'Archivo', sans-serif;
     letter-spacing: 3px;
     margin-top: 30px;
}
 .secondpage_rightside_experience{
     display: flex;
     margin-top: 30px;
}
 .secondpage_rightside_experience_year{
     display: flex;
     justify-content: center;
     align-items: center;
}
 .secondpage_rightside_experience_proj{
     display: flex;
     justify-content: center;
     align-items: center;
     margin-left: 80px;
}
 .fixer{
     font-size: 55px;
     font-family: 'Archivo', sans-serif;
     color: white;
}
 .fixer2{
     display: block;
     max-width: 110px;
     font-size: 20px;
     color: white;
     font-family: 'Archivo', sans-serif;
     margin-left: 15px;
}
 .secondpage_rightside_skillname{
     font-size: 24px;
     font-family: 'Archivo', sans-serif;
     color: white;
     margin-top: 35px;
}
 .secondpage_rightside_skills{
     margin-top: 10px;
     display: flex;
     flex-wrap: wrap;
}
 .secondpage_rightside_skill{
     width: 304px;
     height: 24px;
     background-color: #070f18;
     border-radius: 15px;
}
 .secondpage_rightside_skill_cont{
     display: flex;
     align-items: center;
     margin-top: 20px;
     margin-right: 20px;
}
 progress[value]::-webkit-progress-bar {
     background-color: #070f18;
     border-radius: 15px;
}
 progress[value]::-webkit-progress-value {
     background-color: #2293fd;
     border-radius: 15px;
}
 .secondpage_rightside_skilltxt{
     color: white;
     font-size: 14px;
     font-family: 'Archivo', sans-serif;
     position: absolute;
     margin-left: 20px;
}
 .thirdpage{
     margin: auto;
     width: 85%;
     margin-bottom: 128px;
     display: flex;
     align-items: center;
     justify-content: center;
}
 .thirdpage_header{
     color: white;
     font-size: 25px;
     font-family: 'Archivo', sans-serif;
     text-align: center;
     margin-bottom: 100px;
}
 .thirdpage_container{
}
 .thirdpage_boxes{
     display: flex;
}
 .thirdpage_box{
     max-width: 416px;
     margin-left: 45px;
     margin-right: 45px;
}
 .thirdpage_box_name{
     font-size: 23px;
     color: white;
     font-family: 'Archivo', sans-serif;
     text-align: center;
     margin-top: 40px;
}
 .thirdpage_box_desc{
     text-align: center;
     font-size: 18px;
     font-family: 'Archivo', sans-serif;
     color: rgba(255, 255, 255, 0.575);
     margin-top: 20px;
}
 .thirdpage_box_img1{
     background-image: url(./img/1.png);
     width: 295px;
     height: 295px;
     background-position: center;
     background-repeat: no-repeat;
     margin: auto;
     background-size: cover;
}
 .thirdpage_box_img2{
     background-image: url(./img/2.png);
     width: 295px;
     height: 295px;
     background-position: center;
     background-repeat: no-repeat;
     margin: auto;
     background-size: cover;
}
 .thirdpage_box_img3{
     background-image: url(./img/3.png);
     width: 295px;
     height: 295px;
     background-position: center;
     background-repeat: no-repeat;
     margin: auto;
     background-size: cover;
}
 .fourthpage{
     margin: auto;
     width: 85%;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-bottom: 160px;
}
 .fourthpage_header{
     color: white;
     font-size: 25px;
     font-family: 'Archivo', sans-serif;
     text-align: center;
     margin-bottom: 100px;
     margin-top: 50px;
}
 .fourthpage_container{
}
 .fourthpage_boxes{
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
}
 .fourthpage_box{
     max-width: 416px;
     margin: 5px;
     cursor: pointer;
     position: relative;
     transition: 0.2s;
     display: flex;
     justify-content: center;
     align-items: center;
}
 .fourthpage_box_img{

     width: 388px;
     height: 388px;
     background-position: center;
     background-repeat: no-repeat;
     margin: auto;
     background-size: cover;
}
.p1{
    background-image: url(./img/projects/saechvo.png);

}
.p2{
    background-image: url(./img/projects/history.png);

}
.p3 {
    background-image: url(./img/projects/phone.png);

}
.p4 {
    background-image: url(./img/projects/nemsiscraft.png);

}
// .p5 {
//     background-image: url(./img/projects/CASTTE.png);

// }
.p5 {
    background-image: url(./img/projects/reallife.png);
    
}
.p6 {
    background-image: url(./img/projects/hype.png);

}
.p7 {
    background-image: url(./img/projects/main.png);

}
.p8 {
    background-image: url(./img/projects/shop.png);

}
 .fourthpage_box_img2{
}
 .fourthpage_box_img3{
}
 .fourthpage_box:hover .shadow{
     height: 320px;
     transition: 0.2s;
}
 .fourthpage_box:hover .shadow_cont{
     opacity: 1;
     transition: 0.2s;
}
 .shadow{
     width: 100%;
     height: 0px;
     background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
     position: absolute;
     bottom: 0;
     transition: 0.2s;
}
 .shadow_cont{
     position: absolute;
     bottom: 15px;
     left: 15px;
     max-width: 70%;
     opacity: 0;
     transition: 0.2s;
}
 .fourthpage_projectname{
     font-size: 20px;
     color: white;
     font-family: 'Archivo', sans-serif;
     margin-bottom: 15px;
     margin-left: 15px;
}
 .fourthpage_projectdesc{
     font-family: 'Archivo', sans-serif;
     font-size: 16px;
     color: rgba(255, 255, 255, 0.575);
     margin-left: 15px;
}
 .fifthpage{
     margin: auto;
     width: 82.5%;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-top: 96px;
     margin-bottom: 160px;
}
 .fifthpage_header{
     color: white;
     font-size: 25px;
     font-family: 'Archivo', sans-serif;
     text-align: center;
     margin-bottom: 70px;
}
 .fifthpage_container{
     color: red;
     width: 100%;
     height: 90%;
}
 .fifthpage_boxes{
     display: flex;
}
 .bluer{
     color: #2293fd;
}
 .fifthpage_dec_container{
     width: 100%;
     height: 50px;
     display: flex;
     justify-content: center;
     align-items: center;
}
 .fifthpage_dec_line{
     width: 55%;
     height: 1px;
     background-color: rgba(255, 255, 255, 0.485);
}
 .fifthpage_dec_icon{
     background-image: url(./img/triangle.png);
     background-repeat: no-repeat;
     background-position: center;
     width: 15px;
     height: 15px;
     margin-left: 2%;
     margin-right: 2%;
}
 .fifthpage_inner_cont{
     width: 100%;
     height: 60%;
     display: flex;
     justify-content: center;
     align-items: center;
}
 .fifthpage_inner_social{
     margin-left: 192px;
}
 .fifthpage_links_box{
     display: flex;
     justify-content: right;
     align-items: center;
     margin-bottom: 64px;
}
 .fifthpage_links_box:hover .fifthpage_links_box_decline{
     background-color: rgba(255, 255, 255, 1);
     transition: 0.4s;
}
 .fifthpage_links_box:hover .fifthpage_links_box_left{
     color: white;
     transition: 0.4s;
}
 .fifthpage_links_box_decline{
     height: 50px;
     width: 1px;
     background-color: rgba(255, 255, 255, 0.5);
     margin-left: 20px;
     margin-right: 20px;
     transition: 0.4s;
}
 .fifthpage_links_box_left{
     color: rgba(255, 255, 255, 0.5);
     font-size: 18px;
     font-family: 'Archivo', sans-serif;
     transition: 0.4s;
}
 .fifthpage_links_box_header{
     text-align: right;
     margin-bottom: 15px;
     font-size: 15px;
}
 .fifthpage_links_box_right_phone{
     width: 25px;
     height: 25px;
     background-image: url(./img/phone.png);
     background-position: center;
     background-repeat: no-repeat;
     zoom: 140%;
}
 .fifthpage_links_box_right_location{
     width: 25px;
     height: 25px;
     background-image: url(./img/location.png);
     background-position: center;
     background-repeat: no-repeat;
     zoom: 140%;
}
 .fifthpage_links_box_right_email{
     width: 25px;
     height: 25px;
     background-image: url(./img/email.png);
     background-position: center;
     background-repeat: no-repeat;
     zoom: 140%;
}
 .style.fifthpage.inner_form_head{
     display: flex;
     justify-content: center;
     align-items: center;
}
 .fifthpage_inner_form{
     display: block;
}
 .fifthpage_small_inp{
     width: 210px;
     height: 30px;
     background: rgba(255, 255, 255, 0);
     outline: none;
     border: none;
     color: white;
     border-bottom: 2px solid rgba(255, 255, 255, 0.499) !important;
     -webkit-appearance: none;
     margin-right: 40px;
     font-size: 14px;
     font-family: 'Archivo', sans-serif;
}
 .fifthpage_fx{
     margin-right: 0px !important;
}
 .fifthpage_med_inp{
     width: 97.6%;
     height: 30px;
     background: rgba(255, 255, 255, 0);
     outline: none;
     border: none;
     color: white;
     border-bottom: 2px solid rgba(255, 255, 255, 0.499) !important;
     -webkit-appearance: none;
     margin-top: 20px;
     font-size: 14px;
     font-family: 'Archivo', sans-serif;
     padding-bottom: 5px;
}
 .fifthpage_big_inp{
     width: 97.6%;
     height: 90px;
     background: rgba(255, 255, 255, 0);
     outline: none;
     border: none;
     color: white;
     border-bottom: 2px solid rgba(255, 255, 255, 0.499) !important;
     -webkit-appearance: none;
     margin-top: 20px;
     resize: none;
     font-size: 14px;
     font-family: 'Archivo', sans-serif;
     padding-bottom: 5px;
}
 .fifthpage_inner_form{
     width: 736px;
     margin-left: 192px;
     position: relative;
}
 .fifthpage_big_inp::placeholder , .fifthpage_med_inp::placeholder, .fifthpage_small_inp::placeholder {
     color: rgba(255, 255, 255, 0.499);
}
 input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
}
 .fifthpage_sendbtn{
     width: 320px;
     height: 45px;
     background: none;
     outline: none;
     border: 2px solid rgba(255, 255, 255, 0.499);
     color: rgba(255, 255, 255, 0.499);
     cursor: pointer;
     transition: 0.4s;
     font-weight: 600;
     font-family: 'Archivo', sans-serif;
     float: right;
     margin-right: 14px;
     margin-top: 50px;
}
 .fifthpage_sendbtn:hover{
     background-color: white;
     color: black;
     transition: 0.4s;
     font-weight: 600;
     font-family: 'Archivo', sans-serif;
}
 .fifthpage_success{
     background-color: #35C774 !important;
     color: white;
}
 .fifthpage_error{
     background-color: red !important;
     color: white;
}
 .fifthpage_links_fx{
     margin-bottom: 0px !important;
}
 .fifthpage_inner_link{
     display: flex;
     align-items: center;
     color: white;
     font-family: 'Archivo', sans-serif;
     font-size: 18px;
     margin-bottom: 30px;
     opacity: 0.5;
     transition: 0.4s;
}
 .fifthpage_inner_link:hover{
     opacity: 1;
     transition: 0.4s;
     cursor: pointer;
}
 .fifthpage_inner_linkedin_icon{
     width: 36px;
     height: 36px;
     background-image: url(./img/linkedin.svg);
     background-position: center;
     background-repeat: no-repeat;
}
 .fifthpage_inner_instagram_icon{
     width: 36px;
     height: 36px;
     background-image: url(./img/instagram.svg);
     background-position: center;
     background-repeat: no-repeat;
}
 .fifthpage_inner_facebook_icon{
     width: 36px;
     height: 36px;
     background-image: url(./img/facebook.svg);
     background-position: center;
     background-repeat: no-repeat;
}
 .fifthpage_inner_link_text{
     margin-left: 15px;
}
 .footer{
     background-color: #071727;
     color: rgba(255, 255, 255, 0.682);
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     height: 70px;
     font-family: 'Archivo', sans-serif;
     font-size: 14px;
}
.mobshad {
    opacity: 0.5 !important;
    pointer-events: none !important;
}
.mobtext{
    position: absolute;
    color: white;
    font-size: 40px;
    text-align: center;
    font-family: 'Archivo', sans-serif;
    background-color: #071727;
    padding: 10px;
    border-radius: 10px;
}

 @media only screen and (max-width: 3840px) {
     .header{
         zoom: 200%;
    }
     .firstpage{
         zoom: 200%;
    }
     .secondpage{
         zoom: 200%;
    }
     .thirdpage{
         zoom: 200%;
    }
     .fourthpage{
         zoom: 200%;
    }
     .fifthpage{
         zoom: 200%;
    }
     .footer{
         zoom: 200%;
    }
}
 @media only screen and (max-width: 2560px) {
     .header{
         zoom: 140%;
    }
     .firstpage{
         zoom: 140%;
    }
     .secondpage{
         zoom: 140%;
    }
     .thirdpage{
         zoom: 140%;
    }
     .fourthpage{
         zoom: 136%;
    }
     .fifthpage{
         zoom: 140%;
    }
     .footer{
         zoom: 140%;
    }
}
 @media only screen and (max-width: 1920px) {
     .header{
         zoom: 100%;
    }
     .firstpage{
         zoom: 100%;
    }
     .secondpage{
         zoom: 100%;
    }
     .thirdpage{
         zoom: 100%;
    }
     .fourthpage{
         zoom: 100%;
    }
     .fifthpage{
         zoom: 100%;
    }
     .footer{
         zoom: 100%;
    }
}
 @media only screen and (max-width: 1600px) {
     .header{
         zoom: 80%;
    }
     .firstpage{
         zoom: 80%;
    }
     .secondpage{
         zoom: 80%;
    }
     .thirdpage{
         zoom: 80%;
    }
     .fourthpage{
         zoom: 75%;
    }
     .fifthpage{
         zoom: 75%;
    }
     .footer{
         zoom: 80%;
    }
}
 @media only screen and (max-width: 1440px) {
     .header{
         zoom: 75%;
    }
     .firstpage{
         zoom: 75%;
    }
     .secondpage{
         zoom: 75%;
    }
     .thirdpage{
         zoom: 75%;
    }
     .fourthpage{
         zoom: 75%;
    }
     .fifthpage{
         zoom: 75%;
    }
     .footer{
         zoom: 75%;
    }
}
 @media only screen and (max-width: 1380px) {
     .header{
         zoom: 65%;
    }
     .firstpage{
         zoom: 65%;
    }
     .secondpage{
         zoom: 65%;
    }
     .thirdpage{
         zoom: 65%;
    }
     .fourthpage{
         zoom: 65%;
    }
     .fifthpage{
         zoom: 65%;
    }
     .footer{
         zoom: 65%;
    }
}
 @media only screen and (max-width: 1024px) {
     .header{
         zoom: 55%;
    }
     .firstpage{
         zoom: 55%;
    }
     .secondpage{
         zoom: 55%;
    }
     .thirdpage{
         zoom: 55%;
    }
     .fourthpage{
         zoom: 55%;
    }
     .fifthpage{
         zoom: 55%;
    }
     .footer{
         zoom: 55%;
    }
}
 @media only screen and (max-width: 850px) {
     .header{
         zoom: 50%;
    }
     .firstpage{
         zoom: 45%;
    }
     .secondpage{
         zoom: 50%;
    }
     .thirdpage{
         zoom: 50%;
    }
     .fourthpage{
         zoom: 50%;
    }
     .fifthpage{
         zoom: 50%;
    }
     .footer{
         zoom: 50%;
    }
}
 @media only screen and (max-width: 540px) {
     .header{
    }
     .firstpage_cont{
         flex-wrap: wrap;
    }
     .firstpage_right{
         margin: auto !important;
         margin-top: 48px !important;
         zoom: 95%;
    }
     .firstpage_left{
         text-align: center !important;
    }
     .firstpage_left_button{
         margin: auto;
         margin-top: 64px !important;
    }
     .secondpage_container{
         flex-wrap: wrap;
    }
     .secondpage_leftside_box_avatar{
         margin-left: 0px !important;
    }
     .secondpage_rightside{
         margin-left: 0px !important;
         max-width: 100% !important;
         text-align: center !important;
    }
     .secondpage_rightside_allskills{
         display: flex;
         justify-content: center;
         align-items: center;
         max-width: 90%;
         margin: auto;
         margin-top: 48px !important;
    }
     .secondpage_rightside_experience{
         justify-content: center;
         align-items: center;
    }
     .secondpage_rightside_desc{
         max-width: 70%;
         margin: auto;
         margin-top: 30px !important;
    }
     .thirdpage_boxes{
         flex-wrap: wrap;
         justify-content: center;
    }
     .thirdpage_box{
         margin-bottom: 64px !important;
    }
     .fourthpage_container{
         flex-wrap: wrap;
    }
     .fifthpage_inner_cont{
         flex-wrap: wrap;
    }
     .fifthpage_inner_links{
         display: flex;
         margin-top: 64px;
    }
     .fifthpage_links_fx{
         margin-bottom: 64px !important;
    }
     .fifthpage_links_box{
         margin-left: 16px;
    }
     .fifthpage_inner_form{
         margin-left: 0px !important;
    }
     .fifthpage_inner_social{
         display: flex;
         margin: 0px !important;
         margin-top: 64px !important;
    }
     .fifthpage_inner_link{
         margin-left: 16px !important;
         margin-right: 16px !important;
    }
     .footer{
         zoom: 55%;
    }
}
@media screen and (max-device-width: 480px) {
    .header{
    }
     .firstpage_cont{
         flex-wrap: wrap;
    }
     .firstpage_right{
         margin: auto !important;
         margin-top: 48px !important;
         zoom: 85%;
    }
     .firstpage_left{
         text-align: center !important;
    }
     .firstpage_left_button{
         margin: auto;
         margin-top: 64px !important;
    }
     .secondpage_container{
         flex-wrap: wrap;
    }
     .secondpage_leftside_box_avatar{
         margin-left: 0px !important;
    }
     .secondpage_rightside{
         margin-left: 0px !important;
         max-width: 100% !important;
         text-align: center !important;
    }
     .secondpage_rightside_allskills{
         display: flex;
         justify-content: center;
         align-items: center;
         max-width: 90%;
         margin: auto;
         margin-top: 48px !important;
    }
     .secondpage_rightside_experience{
         justify-content: center;
         align-items: center;
    }
     .secondpage_rightside_desc{
         max-width: 70%;
         margin: auto;
         margin-top: 30px !important;
    }
     .thirdpage_boxes{
         flex-wrap: wrap;
         justify-content: center;
    }
     .thirdpage_box{
         margin-bottom: 64px !important;
    }
     .fourthpage_container{
         flex-wrap: wrap;
    }
     .fifthpage_inner_cont{
         flex-wrap: wrap;
    }
     .fifthpage_inner_links{
         display: flex;
         margin-top: 64px;
    }
     .fifthpage_links_fx{
         margin-bottom: 64px !important;
    }
     .fifthpage_links_box{
         margin-left: 16px;
    }
     .fifthpage_inner_form{
         margin-left: 0px !important;
    }
     .fifthpage_inner_social{
         display: flex;
         margin: 0px !important;
         margin-top: 64px !important;
    }
     .fifthpage_inner_link{
         margin-left: 16px !important;
         margin-right: 16px !important;
    }
    .fifthpage_med_inp{
        border-radius: none !important;
    }
    .fifthpage_small_inp{
        border-radius: none !important;
    }
    .fifthpage_big_inp{
        border-radius: none !important;
    }
     .footer{
         zoom: 55%;
    }
} 
 @media only screen and (max-width: 431px) {
     .header{
         zoom: 45%;
    }
     .firstpage{
         zoom: 45%;
    }
     .secondpage{
         zoom: 45%;
    }
     .thirdpage{
         zoom: 45%;
    }
     .fourthpage{
         zoom: 45%;
    }
     .fifthpage{
         zoom: 35%;
    }
    .fifthpage_links_box_left{
     font-size: 14px;
    }
     .footer{
         zoom: 45%;
    }
    .fourthpage_box{
        zoom: 80%;
    }
}
 @media only screen and (max-width: 300px) {
     .header{
         zoom: 35%;
    }
     .firstpage{
         zoom: 35%;
    }
     .secondpage{
         zoom: 35%;
    }
     .thirdpage{
         zoom: 35%;
    }
     .fourthpage{
         zoom: 35%;
    }
     .fifthpage{
         zoom: 25%;
    }
     .footer{
         zoom: 35%;
    }
}
 